<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div
    class="color-listing__wrapper"
    :class="{ 'button--disabled': !filialeId}"
  >
    <b-form-group
      id="input-group-9"
      :label="$t('FilterRouteByDate')"
      label-for="input-9"
      style="margin-bottom: 10px"
    >
      <div
        class="year"
      >
        <button
          :disabled="!filialeId"
          @click="previousDay"
        >
          <b-icon-arrow-left />
        </button>
        <date-picker
          v-model="selectedDate"
          :disabled="!filialeId"
          value-type="format"
          class="form-control"
          format="DD-MM-YYYY"
          type="date"
          placeholder="Select Date"
          :lang="lang"
          :clearable="false"
        />
        <button
          style="margin-left: 5px"
          :disabled="!filialeId"
          @click="nextDay"
        >
          <b-icon-arrow-right />
        </button>
      </div>
    </b-form-group>
    <!-- <button
      v-b-modal.add-route-store-management
      class="button"
      :class="{'button--disabled': !filialeId }"
      style="border-radius: 6px"
      :disabled="!filialeId"
    >
      Add Route
    </button> -->
    <div
      class="routeTable"
    >
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th
              style="padding: 10px; border-radius: 10px 0px 0px 0px; "
            >
              {{ $t("RouteName") }}
            </th>
            <th
              style="padding: 10px; border-radius: 0px 10px 0px 0px"
            >
              {{ $t("Amount") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="route in getRouteByStorePagination"
            :key="route.storeGLN"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer"
            :style="classNameCh === route.tourId ? 'background-color: #e8e8e8' : ''"
            @click="matProps(route)"
          >
            <td style="padding: 10px">
              {{ route.tourName }}
            </td>
            <td style="padding: 10px">
              {{ route.amount }}CHF
            </td>
          </tr>
          <tr>
            <td
              v-if="sum> 0"
              colspan="10"
              style="
                  padding: 10px;
                  font-weight: bold;
                  font-size: 14px;
                  color: #242F6E;
                  text-align: left;
                "
            >
              {{ $t("Total price for routes") }}: {{ sum.toLocaleString("en-US") }}CHF
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <SelectTour
      :dress-name="filialeId.name"
      @materials="addColor"
    /> -->
    <AddRouteStoreManagement />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddRouteStoreManagement from '@/components/routes/modals/AddRouteModalStoreManagement.vue';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
  components: {
    AddRouteStoreManagement,
    DatePicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['filialeId'],
  data() {
    return {
      configStart: {
        altInput: true,
        altFormat: 'd-m-Y',
        minDate: new Date(),
        dateFormat: 'Y-m-d',
      },
      startDate: null,
      selectedDate: moment().format('DD-MM-YYYY'),
      status: true,
      classNameCh: '',
      totalprice: 0,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },

  computed: {
    ...mapGetters(['getRouteByStorePagination']),
    sum() {
      const routes = this.getRouteByStorePagination;
      if (this.totalprice > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.totalprice = 0;
      }
      routes.forEach((route) => {
        const price = route.amount;
        this.totalprice += price;
      });
      return this.totalprice;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    filialeId() {
      this.classNameCh = '';
    },
    selectedDate: {
      handler(value) {
        // this.$emit('time', value)
        // console.log('date', value);
        //       // this.changeLoadingtoTrue(false);
        console.log(value)
        this.$emit('pass', value);
        this.toursByStorePagination({
          storeGLN: this.filialeId.storeGLN,
          startShift: value,
          pageNumber: 1,
          pageSize: 20,
        })
        // this.toursByStorePagination({
        //   storeGLN: this.filialeId.gln,
        //   startShift: value,
        //   endShift: null,
        //   tourName: null,
        //   pageNumber: 1,
        //   pageSize: 20,
        // })
      },
    },
  },
  methods: {
    ...mapActions([
      'loadTrucksByTourId',
      'toursByStorePagination',
      'resetStaff',
      'changeLoadingtoTrue',
    ]),
    previousDay() {
      this.selectedDate = moment(this.selectedDate, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
    },
    nextDay() {
      this.selectedDate = moment(this.selectedDate, 'DD-MM-YYYY').add(0.5, 'days').format('DD-MM-YYYY');
    },
    matProps(value) {
      this.$emit('click', value);
      this.classNameCh = value.tourId;

      this.loadTrucksByTourId(value.tourId)
      this.resetStaff();
    },
  },
};
</script>

<style scoped lang="scss">
.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  h5 {
    border: #b4b4b4 2px solid;
    border-radius: 6px;
    background-color: whitesmoke;
    width: 40%;
    height: 36px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
}

.color-listing__wrapper{
  margin-top: 15px;
  width: 17%; display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.team_table{
  width: 100% !important;
  box-shadow: 0px 7px 20px rgb(40 41 61 / 8%);
}

.routeTable{
  width: 100%;
  padding-top: 16px;
  margin-top: 0
}

table td{
  color: black;
}
.color-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}
.button {
  margin-bottom: 15px !important;
  width: 195px;
}

.form-control{
  border: none;
  width: 193px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}

@media screen and (max-width: 1200px){
  .color-listing__wrapper{
    margin-left: 5px !important;
  }
  .team_table{
    margin-right: 0px;
}
}

@media screen and (max-width: 1823px) {
  .color-listing__wrapper2 {
    width: 200px;
    button {
      width: 90%;
    }
    .team_table {
      width: 150px !important;
    }
  }
}
@media screen and (max-width: 1041px){
  .color-listing__wrapper {
    margin-left: 0px;
  }

  .form-control{
    width: 100%;
  }

  .year{
    width: 220px;
  }
}
@media screen and (max-width: 1680px) {
  .color-listing__wrapper {
    margin-left: 20px;
    width: 25% !important;
    .team_table {
      width: 275px !important;
    }
  }
  .color-listing__wrapper2 {
    margin-left: 10px;
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .team_table {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 896px) {
  .color-listing__wrapper {
    margin-left: 0 !important;
    flex-basis: 49%;
  }
}
</style>
