<template>
  <div
    class="products-listing__wrapper2"

    style="width: 18%; display: flex; flex-direction: column; align-items: flex-start"
    :style="
      $route.path.includes('products')
        ? ''
        : 'margin-top: 46px; width: 18%; display: flex; flex-direction: column; align-items: flex-start;'
    "
  >
    <button
      v-b-modal.modal-select-truck
      class="button"
      :disabled=" routeId == ''"
      :class="{ 'button--disabled': routeId == ''}"
    >
      {{ $t("SelectTruck") }}
    </button>
    <div
      class="table__header"
      style="margin-bottom: 15px !important; margin-top: 0"
    />
    <div
      style="width: 100%; padding-top: 16px; margin-top: -17px"
      :class="{ 'button--disabled': routeId == ''}"
    >
      <table
        class="team_table"
        style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%)"
      >
        <thead>
          <tr>
            <th style="padding: 10px; border-radius: 10px 10px 0px 0px">
              {{ $t("Truck") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="truck in getTrucksByRouteId"
            :key="truck.truckId"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer"
            :style="classNameCh === truck.truckId ? 'background-color: #e8e8e8' : ''"
            @click="matProps(truck)"
          >
            <td style="padding: 10px">
              {{ truck.registrationPlate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <SelectTruckModal
      :route-id="routeId"
      @addTruck="addTruckToTours"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

// import CreateOrderModal from '@/modals/CreateOrderModal.vue';
import SelectTruckModal from '@/components/storeManagement/modals/SelectTruckModal.vue';
// import EditSize from '@/components/products/modals/EditSize.vue';


export default {
  components: {
    SelectTruckModal,
    // CreateOrderModal,
    // EditSize,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    routeId: {
      type: [String, Object],
    },
  },
  data() {
    return {
      status: true,
      classNameCh: '',
      searchTerm: '',
    };
  },
  computed: {
    ...mapGetters([
      'getRoutesWithPaginationStore',
      'getTrucksByRouteId',
      'getDressColors',
    ]),
  },
  watch: {
    getResetStatus(value) {
      if (value == true) {
        this.rows = [];
        this.counter = 0;
        this.resetCustomOrders2();
      }
    },
  },
  methods: {
    ...mapActions(['changeLoadingtoTrue', 'addTruckToTour', 'truckStaffByTourTruckId', 'loadTrucksByTourId']),

    async addTruckToTours(value) {
      await this.addTruckToTour({
        object: value,
        successCallback: () => {
          this.loadTrucksByTourId(this.routeId.tourId)
        },
      });
    },

    async matProps(value) {
      this.$emit('tourtuck', value)
      this.$emit('hideButton', value)
      this.classNameCh = value.truckId;

      await this.truckStaffByTourTruckId(value.tourTruckId)
    },
  },
};
</script>

<style scoped lang="scss">
.products-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
}
.button {
  background-color: #0000001C;
  margin-bottom: 15px !important;
  width: 195.22px;
}

table td{
  color: black;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1400px) {
  .products-listing__wrapper {
    width: 250px !important;
    margin-right: 5px;
    margin-left: 5px;
  }

  // }
  .products-listing__wrapper2 {
    width: 250px !important;
    margin-left: 5px;
    margin-right: 5px;

    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
  }
}

@media screen and (max-width: 1200px){
  .products-listing__wrapper2{
    width: 150px !important;
    margin-left: 8px;
  }
}

@media screen and (max-width: 1041px){
  .products-listing__wrapper2{
    width: 200px;
    margin-left: 30px;
  }

  .button{
    margin-left: 20px;
  }
}

@media screen and (max-width: 896px) {
  .products-listing__wrapper {
    width: auto !important;
    flex-basis: 30%;
  }
}
</style>
