<template>
  <div>
    <b-modal
      id="modal-select-truck"
      ref="modal"
      header-class="headerModal"
      centered
      size="l"
      :title="$t(&quot;SelectYourTruck&quot;)"
      hide-footer
      @close="onCancel"
      @shown="fetchTrucks"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="$t(&quot;SelectTruck&quot;)"
          label-for="input-1"
        >
          <b-form-select
            id="input-8"
            v-model="truckSelected"
            :options="selectTruck"
            :value="truckId"
            style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 35%);"
            :placeholder="$t(&quot;Select&quot;)"
            label="text"
          />
        </b-form-group>
        <!-- <b-form-group
          id="input-group-1"
          label="Plate"
          label-for="input-1"
        >
          <vue-select
            v-model="$v.form.name.$model"
            required
            :options="['ZU-12831', 'LU-12831']"
            placeholder="Search by plate"
          />
        </b-form-group> -->
        <div>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px;"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form></b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
// import moment from 'moment';
import {
  required,
} from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['routeId'],
  data() {
    return {
      truckSelected: null,
      truckId: null,
      tourId: null,
      errors: {
        duplicateError: false,
      },
      form: {
        truckId: null,
        tourId: null,
      },
    }
  },
  validations: {
    sizeOptions: {
      required,
    },
  },
  computed: {
    ...mapGetters([
      'getFreeTrucks',
    ]),
    selectTruck() {
      return this.getFreeTrucks.map((option) => {
        return {
          value: option.truckId,
          text: option.registrationPlate,
        }
      })
    },
  },
  watch: {
    truckSelected: {
      handler(value) {
        this.form.truckId = value;
      },
      immediate: true,
    },
    'routeId.tourId': {
      handler(value) {
        this.form.tourId = value;
      },
      immediate: true,
    },
  },
  mounted() {
    // this.getMVbyMeter(this.pageM, this.pageSize);
    // this.getMVbyPart(this.pageP, this.pageSize);
  },
  methods: {
    ...mapActions(['loadFreeTrucks', 'loadMaterialVaraintsCategoryNameLike', 'testResetMV']),

    fetchTrucks() {
      this.loadFreeTrucks({
        storeGLN: this.routeId.storeGLN,
        startShift: this.routeId.startShift,
        endShift: this.routeId.endShift,
      }).then((res) => {
        console.log(res);
        // this.changeLoadingtoTrue(true);
      });
    },
    onSubmit() {
      this.$emit('addTruck', this.form)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm()
      }, 10)
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm()
      }, 10)
    },
    resetForm() {
      // Reset our form values
      // this.form.tourId = null;
      this.form.truckId = null;
      this.truckSelected = null;
    },
  },
}
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr ;
  gap: 10px;
}

.close{
  display: none;
}
  .flex-form {
    display: flex;
    flex-direction: column;
  }

  .buttonCancel{
    background-color: white;
    color: black;
  }

  .buttonSubmit{
    background-color: #0000001C;
  }
  input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: white ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
</style>
