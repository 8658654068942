var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    'width': 'auto',
    'margin-top': _vm.getLoggedInUser.role === 'CoTeamLeader' || _vm.getLoggedInUser.role === 'TeamLeader' ? '90px' : '40px'
  }),on:{"click":_vm.removeLists}},[( _vm.getLoggedInUser.role !== 'CoTeamLeader' && _vm.getLoggedInUser.role !== 'TeamLeader')?_c('div',{staticClass:"filialeSearch",style:(_vm.$route.path.includes('products') ? '' : 'padding-bottom: 14px;')},[_c('div',[_c('div',[_c('div',{staticClass:"black"},[(_vm.searchFiliale == '')?_c('b-icon-search',{staticClass:"search1",staticStyle:{"position":"absolute","left":"5px","top":"12px"}}):(_vm.searchFiliale != '' && !_vm.noSearch)?_c('b-icon-x-circle',{staticClass:"search1",staticStyle:{"position":"absolute","margin-top":"-6px","right":"10px","top":"17px","cursor":"pointer"},on:{"click":function($event){_vm.searchFiliale = ''}}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFiliale),expression:"searchFiliale"}],style:(_vm.getFilterStoreName.length > 0 && _vm.noSearch
                ? 'border-radius: 8px 8px 8px 8px'
                : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'),attrs:{"type":"text","autocomplete":"off","name":"search-box","placeholder":_vm.$t("Filiale")},domProps:{"value":(_vm.searchFiliale)},on:{"keyup":_vm.inputChanged,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.onArrowDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.onArrowUp.apply(null, arguments)}],"input":function($event){if($event.target.composing)return;_vm.searchFiliale=$event.target.value}}})],1),(_vm.getFilterStoreName.length > 0 && _vm.noSearch)?_c('div',{ref:"scrollContainer",staticClass:"dropdrop",style:(_vm.getFilterStoreName.length > 7 ? 'height: 210px' : 'height: auto')},_vm._l((_vm.getFilterStoreName),function(result,index){return _c('div',{key:result,ref:"options",refInFor:true,staticClass:"search-item",class:{ 'is-active': index === _vm.arrowCounter },staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.searchByOn(result);
              _vm.noSearch = false;}}},[_c('p',{staticStyle:{"margin":"0"}},[_vm._v(" "+_vm._s(result)+" ")])])}),0):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"tt",staticStyle:{"padding-top":"16px","margin-top":"3px"}},[_c('table',{staticClass:"team_table",staticStyle:{"width":"100%","box-shadow":"0px 7px 20px rgb(40 41 61 / 8%)"},attrs:{"responsive":""}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"padding":"10px","border-radius":"10px 10px 0px 0px"}},[_vm._v(" "+_vm._s(_vm.$t("Filiale"))+" ")])])]),_c('tbody',_vm._l((_vm.getFilialesForStoreManagement),function(filiale){return _c('tr',{key:filiale.storeGLN,staticStyle:{"border-bottom":"1px solid #dcdfe6","color":"#606266","cursor":"pointer"},style:(_vm.classNameCh === filiale.storeId ? 'background-color: #e8e8e8' : ''),on:{"click":function($event){return _vm.matProps(filiale)}}},[_c('td',{staticStyle:{"padding":"10px"}},[_vm._v(" "+_vm._s(filiale.storeName)+" ")])])}),0)]),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.getTotalItemsForFiliales,"per-page":_vm.pageSize,"first-number":"","last-number":"","align":"right","size":"md","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("Previous")))])]},proxy:true},{key:"next-text",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("Next")))])]},proxy:true}]),model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }