<template>
  <div>
    <b-modal
      id="add-employee-to-truck"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t(&quot;AssignEmployee&quot;)"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="freeEmployeess"
    >
      <b-form>
        <div
          v-for="(employee, index) in form.truckStaffReponsibilities"
          :key="index"
        >
          <div style="display: flex;align-items: center;">
            <div style="display: flex;flex-direction: column; width: 100%;">
              <p style="font-size: 16px;;font-weight: bold; width: 100%;margin-bottom: 0px;">
                {{ $t("Select") }}
              </p>
              <vue-select
                v-model="employee.employeeUserId"
                :options="getfreeEmployees.filter(emp => !selectedEmployeeIds.includes(emp.userId))"
                label="userFullName"
                :value="userId"
                style="width:100%;"
                :placeholder="$t(&quot;SelectEmployee&quot;) + (index + 1)"
                @beforeDeselect="checkEmployeeDeselect($event, employee)"
              />
            </div>
            <div style="display: flex;flex-direction: column;width: 50%;padding-left: 10px;align-items: center;">
              <p style="font-size: 16px;;font-weight: bold; width: 100%;margin-bottom: 0px;display: flex;justify-content: center;">
                {{ $t("Driver") }}
              </p>
              <b-form-checkbox
                :id="'index'"
                v-model="employee.isDriver"
                name="plain-inline"
                :value="true"
                :disabled="isDriverInArray"
                :unchecked-value="false"
                @change="uncheckedValueIsDriver(employee)"
              />
            </div>
            <div style="display: flex;flex-direction: column;width: 50%;align-items: center;">
              <p style="font-size: 16px;;font-weight: bold; width: 100%;margin-bottom: 0px;display: flex;justify-content: center;">
                {{ $t("Responsible") }}
              </p>
              <b-form-checkbox
                :id="'index'"
                v-model="employee.isResponsible"
                :disabled="isResponsibleInArray"
                name="plain-inline1"
                :value="true"
                :unchecked-value="false"
                @change="uncheckedValueIsResponsible(employee)"
              />
            </div>
            <div
              style="height:40px; width: 40px; background-color: white; border-radius: 10px; display: flex; justify-content: center; align-items: center; cursor: pointer;padding-left: 10px;"
              :disabled="form.truckStaffReponsibilities.length <=1"
              @click="removeEmployee(index)"
            >
              <b-icon-trash
                :disabled="form.truckStaffReponsibilities.length == 1"

                style="width: auto; height: 20px; color: black; "
              />
            </div>
          </div>
          <!-- <div style="font-size: 16px; line-height: 24px;font-weight: bold; width: 100%; ">
            {{ $t("Select") }}
            <div style="float: right; padding-right: 28px;font-size: 16px; line-height: 24px;font-weight: bold; ">
              {{ $t("Responsible") }}
            </div>
            <div style="float: right; padding-right: 14px; font-size: 16px; line-height: 24px;font-weight: bold; ">
              {{ $t("Driver") }}
            </div>

            <div
              style="display: flex;flex-direction: row; margin-top: 10px; width: 100%;"
            >
              <vue-select
                v-model="employee.employeeUserId"
                :options="getfreeEmployees.filter(emp => !selectedEmployeeIds.includes(emp.userId))"
                label="userFullName"
                :value="userId"
                style="width:50%;"
                :placeholder="$t(&quot;SelectEmployee&quot;) + (index + 1)"
                @beforeDeselect="checkEmployeeDeselect($event, employee)"
              />
              <div style="width: 40%; display: flex; justify-content: space-evenly;">
                <b-form-checkbox
                  :id="'index'"
                  v-model="employee.isDriver"
                  name="plain-inline"
                  :value="true"
                  :disabled="isDriverInArray"
                  :unchecked-value="false"
                  @change="uncheckedValueIsDriver(employee)"
                />
                <b-form-checkbox
                  :id="'index'"
                  v-model="employee.isResponsible"
                  :disabled="isResponsibleInArray"
                  name="plain-inline1"
                  :value="true"
                  :unchecked-value="false"
                  @change="uncheckedValueIsResponsible(employee)"
                />
              </div>
            </div>
          </div> -->


        </div>
        <b-button
          type="button"
          style="margin: 15px 0px 0px; height: 39px; background: transparent linear-gradient(53deg, #0071AE 0%, #55B94A 100%) 0% 0% no-repeat padding-box;"
          size="sm"
          @click="addNewRow"
        >
          <p style="margin: 0">
            + {{ $t("AddEmployee") }}
          </p>
        </b-button>
        <pre />
        <div>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            :disabled="isSubmitDisabled"
            @click="onSubmit"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['filialeId', 'routeId', 'truckId', 'isDriverInArray', 'isResponsibleInArray'],

  data() {
    return {
      userId: null,
      tourTruckId: null,
      form: {
        truckStaffReponsibilities: [
          {
            employeeUserId: '',
            isDriver: false,
            isResponsible: false,
          },
        ],
        startTour: this.routeId.startShift,
        endTour: this.routeId.endShift,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getfreeEmployees',
    ]),
    selectedEmployeeIds() {
      return this.form.truckStaffReponsibilities.map((emp) => { return emp.employeeUserId.userId });
    },
    isSubmitDisabled() {
      for (let i = 0; i < this.form.truckStaffReponsibilities.length; i++) {
        if (
          this.form.truckStaffReponsibilities[i].employeeUserId == ''
          || this.form.truckStaffReponsibilities[i].employeeUserId == null
        ) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    'routeId.startShift': {
      handler(value) {
        this.form.startTour = value
      },
    },
    'routeId.endShift': {
      handler(value) {
        this.form.endTour = value
      },
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['freeEmployee']),
    removeEmployee(index) {
      this.form.truckStaffReponsibilities.splice(index, 1);
    },
    // removeSelectedEmployee(index) {
    //   const selectedEmployees = this.form.truckStaffReponsibilities.map((emp) => { return emp.employeeUserId });
    //   const filteredEmployees = this.getfreeEmployees.filter((emp) => { return !selectedEmployees.includes(emp.userId) });
    //   this.filteredEmployees[index] = filteredEmployees;
    // },
    uncheckedValueIsResponsible(clickedEmployee) {
      this.form.truckStaffReponsibilities.forEach((employee) => {
        if (employee !== clickedEmployee) {
          employee.isResponsible = false;
        }
      });
    },
    uncheckedValueIsDriver(clickedEmployee) {
      this.form.truckStaffReponsibilities.forEach((employee) => {
        if (employee !== clickedEmployee) {
          employee.isDriver = false;
        }
      });
    },
    checkEmployeeDeselect(deselectedValue, employee) {
      if (deselectedValue && deselectedValue.userId === employee.employeeUserId.userId) {
        employee.employeeUserId = null;
      }
    },
    onRemove() {
      this.freeEmployee({
        storeId: this.filialeId.storeId, startDate: this.routeId.startShift, endDate: this.routeId.endShift,
      });
      // console.log(removedOption)
    // Call any other methods or update any data as necessary
    },
    freeEmployeess() {
      this.freeEmployee({
        storeId: this.filialeId.storeId, startDate: this.routeId.startShift, endDate: this.routeId.endShift,
      });
      // console.log(this.freeEmployee)
      this.tourTruckId = this.truckId.tourTruckId;
      this.form.startTour = this.routeId.startShift;
      this.form.endTour = this.routeId.endShift;
    },
    addNewRow() {
      this.form.truckStaffReponsibilities.push({
        employeeUserId: '',
        isDriver: false,
        isResponsible: false,
      })
    },
    onSubmit() {
      Promise.resolve()
        .then(() => {
          this.$emit('click', { param1: this.form, param2: this.tourTruckId });
          // this.$emit('test', this.tourTruckId);
        })
        .then(() => {
          this.$refs.modal.hide();
        })
        .then(() => {
          this.onReset();
        })

      // setTimeout(() => {
      //   this.$v.$reset();
      // }, 0);
      // this.$nextTick(() => {
      //   this.$v.$reset();
      // });
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.form.truckStaffReponsibilities.splice(1);
      this.form.truckStaffReponsibilities.forEach((truckStaffReponsibilities) => {
        truckStaffReponsibilities.employeeUserId = '';
        truckStaffReponsibilities.isDriver = false;
        truckStaffReponsibilities.isResponsible = false;
      });
      this.form.startTour = null;
      this.form.endTour = null;
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}

.modal-content{
  width: 70%;
}
</style>
