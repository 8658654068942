<template>
  <div>
    <b-modal
      id="cancel-employee-in-store"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('RemoveEmployee')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <p>{{ name }} - {{ $t('AreYouSureForRemoving') }} </p>
      </b-form>
      <div>
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['vacationId', 'truckStaffId', 'name'],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['getfreeTeamLeaders', 'getVacationRequests']),

  },
  methods: {
    ...mapActions(['loadUsersFullNamesById', 'cancelVacation']),
    onSubmit() {
      this.$refs.modal.hide()
      this.$emit('cancel', this.truckStaffId);
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
    },
  },
};
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
