<template>
  <div>
    <b-modal
      id="add-route-store-management"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('SubmitNewUser')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          label="Route Name"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.personalInfoForm.firstName.$model"
            placeholder="ZU-LU"
            :state="validateState('firstName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Price"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.personalInfoForm.firstName.$model"
            placeholder="2000CHF"
            :state="validateState('firstName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-9"
          label="Starting Date"
          label-for="input-9"
        >
          <flat-pickr
            v-model="startDate"
            class="form-control"
            :config="configStart"
            placeholder="Select Date"
          />
        </b-form-group>
        <b-form-group
          id="input-group-9"
          label="Ending Date"
          label-for="input-9"
        >
          <flat-pickr
            v-model="endDate"
            class="form-control"
            :config="configEnd"
            placeholder="Select Date"
          />
          <p
            v-if="showError"
            style="color:#ff9966 ; font-size: smaller;"
          >
            Important! <br> Endind date should be after starting date!
          </p>
        </b-form-group>
        <b-form-checkbox
          id="checkbox-1"
          name="checkbox-1"
          :value="true"
          :unchecked-value="false"
          @input="setTime(pickUpInStore)"
        >
          All day
        </b-form-checkbox>
        <pre />
        <b-form-group
          id="input-group-6"
          label="Start Time"
          label-for="input-6"
        >
          <b-form-timepicker
            v-model="startTime"
            locale="en"
            label-no-time-selected="--:--"
            dropleft
            required
            reset-button
          />
        </b-form-group>
        <b-form-group
          id="input-group-6"
          label="Start Time"
          label-for="input-6"
        >
          <b-form-timepicker
            v-model="endTime"
            locale="en"
            label-no-time-selected="--:--"
            dropleft
            required
            reset-button
          />
        </b-form-group>
        <div>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmitAddUser"
          >
            {{ $t('Submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, alpha, minValue, numeric, alphaNum, email,
} from 'vuelidate/lib/validators';

export default {
  components: {
    flatPickr,
  },
  mixins: [validationMixin],
  data() {
    return {
      showError: false,
      startDate: '',
      endDate: '',
      endTime: '',
      startTime: '',
      configStart: {
        altInput: true,
        altFormat: 'd-m-Y',
        minDate: new Date(),
        dateFormat: 'd-m-Y',
      },
      configEnd: {
        altInput: true,
        altFormat: 'd-m-Y',
        minDate: new Date(),
        dateFormat: 'd-m-Y',
      },
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee'],
      personalInfoForm: {
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        houseNumber: '',
        street: '',
        country: '',
        passport: '',
        aufenthaltsbewilligung: '',
        ahv: '',
        email: '',
        role: '',
      },
      workInfoForm: {
        startingDate: '',
        endingDate: '',
        workload: '',
        vacationDays: '',
        employeeType: '',
        role: '',
        salary: '',
      },
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
    };
  },
  validations: {
    personalInfoForm: {
      firstName: {
        required,
        minLength: minLength(3),
        alpha,
      },
      lastName: {
        required,
        minLength: minLength(3),
        alpha,
      },
      salutation: {
        required,
      },
      gender: {
        required,
      },
      city: {
        required,
        minLength: minLength(3),
        alpha,
      },
      // dateOfBirth: {
      //   required,
      //   dateFormat,
      // },
      // phoneNumber: {
      //   required,
      //   minLength: minLength(9),
      //   numeric,
      // },
      postalCode: {
        required,
        minLength: minLength(3),
        alphaNum,
      },
      doorNumber: {
        required,
        minLength: minValue(1),
        numeric,
      },
      street: {
        required,
        minLength: minLength(3),
      },
      country: {
        required,
        minLength: minLength(3),
        alpha,
      },
      email: {
        required,
        email,
      },
      role: {
        required,
      },
    },
  },
  computed: {
    // getSal() {
    //   return this.$store.state.login.salutation;
    // },
  },
  watch: {
    // eslint-disable-next-line func-names
    startDate(newValue) {
      if (newValue > this.endDate) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    endDate(newValue) {
      if (newValue < this.startDate) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
  },
  methods: {
    setTime(value) {
      if (value == true) {
        this.endTime = '17:00'
        this.startTime = '8:00'
      } else {
        this.endTime = ''
        this.startTime = ''
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.personalInfoForm[name];
      return $dirty ? !$error : null;
    },
    onSubmitAddUser() {
      this.$v.personalInfoForm.$touch();
      if (this.$v.personalInfoForm.$anyError) {
        return;
      }
      this.$emit('click', this.personalInfoForm)
      this.$refs.modal.hide()
      this.onReset()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.personalInfoForm.firstName = '';
      this.personalInfoForm.lastName = '';
      this.personalInfoForm.salutation = '';
      this.personalInfoForm.gender = '';
      this.personalInfoForm.city = '';
      this.personalInfoForm.street = '';
      this.personalInfoForm.dateOfBirth = '';
      this.personalInfoForm.postalCode = '';
      this.personalInfoForm.doorNumber = '';
      this.personalInfoForm.country = '';
      this.personalInfoForm.phoneNumber = '';
      this.personalInfoForm.email = '';
      this.personalInfoForm.role = '';
      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

      <style lang="scss" scoped>
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .form {
        display: grid;
        grid-template-columns: 1fr 1fr ;
        gap: 10px;
      }
      .form2 {
        display: grid;
        grid-template-columns: 1fr 1fr ;
        gap: 10px;
      }
      .flex-form {
        display: flex;
        flex-direction: column;
      }
      .uploadButtons{
        display: flex;
        align-items:flex-end;
      }
      </style>
