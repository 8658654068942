<template>
  <div class="home">
    <!-- <Navbar />
    <Sidebar /> -->
    <section class="main product__management">
      <StoreFiliale
        :time2="routeee"
        @click="dressId"
      />
      <StoreTour
        :filiale-id="filialeIdShow"
        @click="routeId"
        @time="time1"
        @pass="routee"
      />
      <StoreTrucks
        :filiale-id="filialeIdShow"
        :route-id="routeIdShow"
        @tourtuck="truckId"
      />
      <StoreEmployee
        :filiale-id="filialeIdShow"
        :route-id="routeIdShow"
        :truck-id="truckShow"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';
import { mapGetters } from 'vuex';
import StoreFiliale from '@/components/storeManagement/StoreFiliale.vue';
import StoreTour from '@/components/storeManagement/StoreTour.vue';
import StoreTrucks from '@/components/storeManagement/StoreTrucks.vue';
import StoreEmployee from '@/components/storeManagement/StoreEmployee.vue';

export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    StoreFiliale,
    StoreTour,
    StoreTrucks,
    StoreEmployee,
  },
  data() {
    return {
      filialeIdShow: '',
      routeIdShow: '',
      truckShow: {},
      time2: null,
      routeee: null,
    };
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  watch: {
    // eslint-disable-next-line func-names
    // inside(item) {
    //   this.truck = item;
    // },
  },
  mounted() {
  },
  methods: {
    routee(value) {
      console.log('sss', value)
      this.routeee = value
    },
    time1(value) {
      // console.log('time1', value)
      this.time2 = value
    },
    truckId(item) {
      this.truckShow = item;
    },
    dressId(id) {
      this.filialeIdShow = id;
      this.routeIdShow = ''
      this.truckShow = {}
    },
    routeId(id) {
      this.routeIdShow = id;
      this.truckShow = {}
    },
  },
};
</script>

<style lang="scss" scoped>
.product__management .hide-on-products {
  display: none;
}

.main{
  padding: 90px 15px 15px 110px;
}

@media screen and (max-width: 1400px){
  .main{
    padding: 90px 15px 15px 120px;
  }
}

@media screen and (max-width: 1200px){
  .main{
    display: flex;
    padding: 90px 15px 15px 90px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1041px) {
  .main {
    padding: 90px 0.75rem 0 1.75rem;
  }
}
@media screen and (max-width: 896px) {
  .main {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
</style>
