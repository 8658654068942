<!-- eslint-disable vue/no-template-shadow -->
<template>
  <!-- :class="$route.path.includes('products') ? 'mats-listing__wrapper2' : 'mats-listing__wrapper'" -->
  <div
    :style="{
      'width': 'auto',
      'margin-top': getLoggedInUser.role === 'CoTeamLeader' || getLoggedInUser.role === 'TeamLeader' ? '90px' : '40px'
    }"
    @click="removeLists"
  >
    <div
      v-if=" getLoggedInUser.role !== 'CoTeamLeader' && getLoggedInUser.role !== 'TeamLeader'"
      class="filialeSearch"
      :style="$route.path.includes('products') ? '' : 'padding-bottom: 14px;'"
    >
      <div>
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchFiliale == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchFiliale != '' && !noSearch"
              class="search1"
              style="position: absolute; margin-top: -6px; right: 10px; top: 17px; cursor: pointer"
              @click="searchFiliale = ''"
            />
            <input
              v-model="searchFiliale"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t(&quot;Filiale&quot;)"
              :style="
                getFilterStoreName.length > 0 && noSearch
                  ? 'border-radius: 8px 8px 8px 8px'
                  : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'
              "
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilterStoreName.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilterStoreName.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilterStoreName"
              :key="result"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="
                searchByOn(result);
                noSearch = false;
              "
            >
              <p style="margin: 0">
                {{ result }}
              </p>
            </div>
            <!-- <div
              v-if="getFilterStoreName.length == 0"
              class="search-item"
            >
              <p>Sorry. No Results.</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <button
      v-b-modal.modal-select-filiale
      class="button"
      style="border-radius:6px"
    >
      Select Filiale
    </button> -->
    <div
      class="tt"
      style="padding-top: 16px; margin-top: 3px;"
    >
      <table
        responsive
        class="team_table"
        style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%)"
      >
        <thead>
          <tr>
            <th
              style="padding: 10px; border-radius: 10px 10px 0px 0px"
            >
              {{ $t("Filiale") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="filiale in getFilialesForStoreManagement"
            :key="filiale.storeGLN"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer"
            :style="classNameCh === filiale.storeId ? 'background-color: #e8e8e8' : ''"
            @click="matProps(filiale)"
          >
            <td style="padding: 10px">
              {{ filiale.storeName }}
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="getTotalItemsForFiliales"
          :per-page="pageSize"
          first-number
          last-number
          align="right"
          size="md"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span>{{ $t("Previous") }}</span>
          </template>
          <template #next-text>
            <span>{{ $t("Next") }}</span>
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
// import SelectFiliale from '@/components/storeManagement/modals/SelectFilialeModal.vue';
// import DeleteDressModal from '@/components/products/modals/DeleteDressModal.vue';

export default {
  components: {
    // SelectFiliale,
    // DeleteDressModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['time2'],
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      toggleSarch: false,
      noSearch: false,
      searchFiliale: '',
      classNameCh: '',
      page: 1,
      pageSize: 15,
      pageNumber: 1,
      arrowCounter: 0,
      enterPressed: false,
    };
  },
  computed: {
    ...mapGetters([
      'getFilterStoreName',
      'getDresses',
      'getCurrentPage',
      'getTotalItemsForDresses',
      'getExtendedFiliales',
      'getTotalItemsForFiliales',
      'getFilialesForStoreManagement',
      'getLoggedInUser',
    ]),
    activeClass() {
      if (this.item.name === this.classNameCh) {
        return 'active';
      }
      return '';
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    // initialId() {
    //   this.immediateId(this.initialId);
    // },
    page(value) {
      this.loadFilialePaginationForStoreManagement({
        status: true,
        storeName: this.searchFiliale,
        pageSize: this.pageSize,
        pageNumber: value,
      });
    },
    searchFiliale(value) {
      if (value == '') {
        this.removeLists();
        this.loadFilialePaginationForStoreManagement({
          status: true,
          storeName: value,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else {
        this.loadFilteredStoreName(value);
        this.noSearch = true;
      }
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions([
      'loadFilteredStoreName',
      'loadRouteByPaginationForStore',
      'resetStaff',
      'resetTrucks',
      'changeLoadingtoTrue',
      'resetFilteredDresses',
      'loadFilialePaginationForStoreManagement',
      'toursByStorePagination',
    ]),
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false);


        await this.loadFilialePaginationForStoreManagement({
          status: true,
          storeName: this.searchFiliale,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilterStoreName.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },

    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilterStoreName[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    matProps(value) {
      this.$emit('click', value);
      this.classNameCh = value.storeId;
      this.toursByStorePagination({
        storeGLN: value.storeGLN,
        startShift: this.time2 || moment().format('DD-MM-YYYY'),
        pageNumber: 1,
        pageSize: 10,
      })
      this.resetStaff();
      this.resetTrucks();
    },
    async searchByOn(value) {
      this.searchFiliale = value;
      this.noSearch = false;
      await this.loadFilialePaginationForStoreManagement({
        status: true,
        storeName: this.searchFiliale,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
    removeLists() {
      this.noSearch = false;
    },
  },
};
</script>

<style scoped lang="scss">
.mats-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
}

table td{
  color: black;
}
.button {
  margin-bottom: 15px !important;
  margin-top: 14px;
  width: 195px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}
.justify-content-end {
  justify-content: flex-start !important;
}
// .team_table .tr{
//   border-radius: 10px 10px 10px 10px !important;
// }
tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .mats-listing__wrapper2 {
    width: 250px !important;
  }
  .tt {
    width: 250px;
  }
}
@media screen and (max-width: 1400px) {
  .mats-listing__wrapper {
    width: 150px !important;
    margin-top: 59px !important;
    margin-right: 5px;
    .tt {
      width: 200px;
    }
  }
  .mats-listing__wrapper2 {
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .tt {
      width: 200px;
    }
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.black {
  position: relative;
}
.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.filialeSearch{
  display: flex
}

@media screen and (max-width: 1041px){
  .mats-listing__wrapper{
    margin-right: 0px;
  }
}
@media screen and (max-width: 896px) {
  .mats-listing__wrapper {
    width: auto !important;
    flex-basis: 49%;
  }
  .tt {
    margin-right: 0 !important;
    width: auto !important;
  }
}

@media screen and (max-width: 1200px){
  .mats-listing__wrapper2 {
    width: 165px !important;
  }
  .tt {
    width: 165px !important;
  }

  .filialeSearch{
    width: 165px !important;
  }
}
.is-active {
  background-color: #dedede;
}
.search1 {
  padding: 0px;
}
</style>
