<template>
  <div
    class="inside-product__wrapper"
    style="display: flex; flex-direction: column; align-items: flex; margin-top: 46px"
  >
    <div style="margin-bottom: 12px">
      <!-- v-if="$route.path.includes('create-order') && getDressColors.length == 0" -->
      <button
        v-b-modal.add-employee-to-truck
        class="button"
        :disabled="Object.keys(truckId).length === 0"
        :class="{ 'button--disabled': Object.keys(truckId).length === 0}"
      >
        <b-icon-plus />
        {{ $t("AddEmployee") }}
      </button>
    </div>
    <div
      style="width: 100%; padding-top: 16px; margin: 0;"
      :class="{ 'button--disabled': getStaffByTourTruckId.length == 0}"
    >
      <table
        class="team_table"
        style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); text-align: left;"
      >
        <thead>
          <tr>
            <th>
              {{ $t("EmployeeName") }}
            </th>
            <th style="padding: 10px">
              {{ $t("EmployeeEmployeeT") }}
            </th>
            <th style="padding: 10px">
              {{ $t("IsDriver") }}
            </th>
            <th style="padding: 10px">
              {{ $t("IsResponsible") }}
            </th>
            <th style="padding: 10px">
              {{ $t("PhoneNumber") }}
            </th>
            <th>
              {{ $t("Cancel") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="employee in getStaffByTourTruckId"
            :key="employee.employeeUserId"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer"
          >
            <td style="padding: 10px">
              {{ employee.fullName }}
            </td>
            <!-- <td style="padding: 10px; background-color: #8FBC8F    ;">
              {{ employee.employeeType }}
            </td> -->
            <td
              v-if="employee.employeeType == 'Internal'"
              style="padding: 10px; background-color: #8FBC8F    ;"
            >
              <p style="font-weight: bold;">
                {{ $t('Internal') }}
              </p>
            </td>
            <td
              v-else
              style="padding: 10px; background-color: #FFD700    "
            >
              <p style="font-weight: bold;">
                {{ $t('External') }}
              </p>
            </td>
            <td
              v-if="employee.isDriver == true"
              style="padding: 10px"
            >
              <img
                src="../../assets/car-steering-wheel.png"
                alt=""
                style="width: 20px"
              >
            </td>
            <td
              v-else
              style="padding: 10px"
            />
            <td
              v-if="employee.isResponsible == true"
              style="padding: 10px"
            >
              <img
                src="../../assets/check.png"
                alt=""
                style="width: 20px"
              >
            </td>
            <td
              v-else
              style="padding: 10px"
            />
            <td style="padding: 10px">
              +{{ employee.countryCode }} {{ employee.phoneNumber }}
            </td>
            <td
              v-b-modal.cancel-employee-in-store
              style="padding: 9px; text-align: left; cursor: pointer"
              :disabled="getStaffByTourTruckId.length <= 1"
              @click="cancelRequest(employee.truckStaffId, employee.fullName)"
            >
              <p
                class="p-0 m-0 ml-50"
                :disabled="getStaffByTourTruckId.length <= 1"
              >
                <b-icon-trash />
              </p>
            </td>
          </tr>
          <AddEmployeeToTruck
            :filiale-id="filialeId"
            :route-id="routeId"
            :truck-id="truckId"
            :is-driver-in-array="isDriverInArray"
            :is-responsible-in-array="isResponsibleInArray"
            @click="addTruckStaff"
            @test="tourTruckIdd"
          />
          <CancelEmployee
            :name="employeeName"
            :truck-staff-id="employeeId"
            @cancel="removeEmployee"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddEmployeeToTruck from '@/components/employees/modals/AddEmployeeToTruck.vue';
import moment from 'moment'
import CancelEmployee from './modals/CancelEmployeeInTour.vue'

export default {
  components: {
    AddEmployeeToTruck,
    CancelEmployee,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['filialeId', 'routeId', 'truckId'],

  data() {
    return {
      dummy: '',
      searchTerm: '',
      rows: [],
      tourTruckId: null,
      employeeId: null,
      employeeName: null,
    };
  },
  computed: {
    ...mapGetters(['getStaffByTourTruckId', 'getTrucksByRouteId']),
    isDriverInArray() {
      return this.getStaffByTourTruckId.some((obj) => { return obj.isDriver === true });
    },
    isResponsibleInArray() {
      return this.getStaffByTourTruckId.some((obj) => { return obj.isResponsible === true });
    },
    isButtonDisabled() {
      return [this.filialeId, this.routeId, this.truckId].some((p) => { return p === undefined || p === null });
    },
  },
  watch: {
    productMatObj() {
      this.addRows();
    },
  },
  methods: {
    ...mapActions(['addOrderInStore', 'releaseEmployeeFromTour', 'getMaterialVariantsToMDCPagination', 'addTruckStuff', 'truckStaffByTourTruckId', 'releaseAndReplaceEmployeeFromTour']),
    isTruckIdEmpty() {
      return !this.truckId || Object.keys(this.truckId).length === 0;
    },
    async createCustomOrder(obj) {
      await this.addOrderInStore(obj);
    },
    async addTruckStaff(event) {
      await this.addTruckStuff({
        object: event.param1,
        tourTruckId: event.param2,
        successCallback: () => {
          this.truckStaffByTourTruckId(event.param2)
        },
      });
    },
    tourTruckIdd(value) {
      this.tourTruckId = value;
    },

    cancelRequest(value, value2) {
      this.employeeId = value
      this.employeeName = value2
    },

    // tdClass(row) {
    //   return row.vgtSelected ? 'row-clicked' : '';
    // },

    // onRowClick(p) {
    //   return p.selected ? 'selected' : '';
    // },
    async getMaterialVByMandP() {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 10,
        measurement: 'Meter',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 10,
        measurement: 'Part',
      });
    },

    removeEmployee(value) {
      // this.releaseAndReplaceEmployeeFromTour({
      //   obj: {
      //     truckStaffId: value,
      //     message: 'string',
      //     dateReleased: moment().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      //   },
      //   successCallback: () => {
      //     this.truckStaffByTourTruckId(this.truckId.tourTruckId)
      //   },
      // })
      this.releaseEmployeeFromTour({
        obj: {
          truckStaffId: value,
          message: '',
          dateReleased: moment().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        },
        successCallback: () => {
          this.truckStaffByTourTruckId(this.truckId.tourTruckId)
        },
      })
    },
  },
};
</script>

<style scoped lang="scss">
.orange-color {
  color: orange;
}
.red-color {
  color: red;
}
.green-color {
  color: green;
}
table td{
  color: black;
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

table th{
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #dcdfe6;
  color: #262E6C;
  opacity: 1;
  font-size: 16px;
  box-shadow: 0px 17px 45px #0000000F;
}

tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .inside-product__wrapper {
    margin-left: 20px;
  }
}
@media screen and (max-width: 1400px) {
  .inside-product__wrapper {
    width: 200px !important;
    margin-left: 0px !important;
    // button{
    //   font-size: 11px;
    //   width: 100% !important;
    //   height: 36px;
    // }
  }
}
@media screen and (max-width: 896px) {
  .inside-product__wrapper {
    width: auto !important;
    flex-basis: 50%;
  }
}
</style>
